import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import SkeletonComponent from '../utils/Skeleton.tsx';
import FullscreenSpinner from './FullscreenSpinner.tsx';
import CustomSnackbar from '../utils/SnackBarCustomComponent.tsx';
import DialogComponent from './DialogComponent.tsx';
import EditIcon from '@mui/icons-material/Edit';
import ApplyDialogComponent from './ApplyDialogComponent.tsx';

interface TableRow {
  id: string;
  content_id: string;
  old_label: string;
  new_label: string | null;
  old_artist?: string;
  new_artist?: string | null;
  modified: boolean;
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

export default function CodificaTable({ dashboardName }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableRow[]>([]);
  const [isError, setIsError] = React.useState({ label: '', message: '', correct: false });
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openApplyDialog, setOpenApplyDialog] = React.useState(false);
  const [typeOperation, setTypeOperation] = React.useState('');
  const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);

  const columns: GridColDef<TableRow>[] = (dashboardName === 'music')?
  [
    {
      field: 'old_label',
      headerName: 'Label originale',
      flex: 1,
      resizable: false,
    },
    {
      field: 'new_label',
      headerName: 'Label codificata',
      editable: true,
      flex: 1,
      resizable: false,
    },
    {
      field: 'old_artist',
      headerName: 'Label artista originale',
      flex: 1,
      resizable: false,
    },
    {
      field: 'new_artist',
      headerName: 'Label artista codificata',
      editable: true,
      flex: 1,
      resizable: false,
    },
    {
      field: 'modified',
      headerName: 'Status',
      editable: false,
      flex: 0.1,
      resizable: false,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modificato"
          />
        </div>
      ),
    },
  ]
  :[
    {
      field: 'old_label',
      headerName: 'Label originale',
      flex: 1,
      resizable: false,
    },
    {
      field: 'new_label',
      headerName: 'Label codificata',
      editable: true,
      flex: 1,
      resizable: false,
    },
    {
      field: 'modified',
      headerName: 'Status',
      editable: false,
      flex: 0.1,
      resizable: false,
      renderCell: (params) => (
        params.value === true ? (
          <div style={{ textAlign: "center" }}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Modificato"
            />
          </div>
        ) : null
      ),
    },
  ];

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection);
  };

  // Use processRowUpdate to handle row updates
  const processRowUpdate = (newRow: TableRow) => {
    let updatedRows:any= []
    if(dashboardName === 'music')
      updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, new_label: newRow.new_label, new_artist: newRow.new_artist, status:'updated'} : row // Update the corresponding row
      );
    else
      updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, new_label: newRow.new_label, status:'updated'} : row // Update the corresponding row
      );
    setRows(updatedRows); // Set the updated rows to state
    return newRow; // Return the updated row
  };

  const applyLabelData = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'apply_labels', {table_name: dashboardName})
        .then((response) => { })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'applicazione della codifica sui dati`, correct: false }); });
    })
  };

  const updateLabelData = (updatedLabelData, type) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'save_labels', {table_name: dashboardName, labels: updatedLabelData})
        .then((response) => { if(type === 'apply') applyLabelData() })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'aggiornamento dei dati`, correct: false }); });
    })
  };

  const resetLabelData = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'reset_labels', {table_name: dashboardName} )
        .then((response) => {
          callMutationUserData();
          setIsLoading(false);
          setOpen(true);
          setIsError({ label: 'correct', message: `Dati ripristinati`, correct: true });
        })
        .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante il ripristino dei dati`, correct: false });});
    });
  };

  const getLabelData = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'get_labels', {table_name: dashboardName} )
        .then((response) => {
          resolve({ data: response.data });
        })
        .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
    });
  };

  function callMutationUserData() {
    setIsLoading(true)
    getLabelData()
    .then((response: any) => {
      let labelData: TableRow[] = response.data;
      labelData = labelData.map((el, index) => ({ ...el, id: el.content_id+'_'+index }));
      setRows(labelData);
      setIsLoading(false)
    })
    .catch((error) => { setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
  }
   
  React.useEffect(() => {
    callMutationUserData();
  }, [dashboardName]);

  function handleSave(type) {
    let selectedData: any[] = rows.filter((row) => selectedRows.includes(row.id));
    if (selectedData.length === 0){selectedData = rows.filter(el => el.hasOwnProperty('status'))}

    if (dashboardName !== 'music') {selectedData = selectedData.map(el => {return {content_id:el.content_id, new_label: el.new_label }})}
    else {selectedData = selectedData.map(el => {return {content_id:el.content_id, new_label: el.new_label, new_artist: el.new_artist }})}

    updateLabelData(selectedData, type)
  }

  const handleRefresh = () => {
    callMutationUserData();
  };

  function handleReset() {
    setIsLoading(true)
    resetLabelData();    
  }

  function handlerOpenDialog (type) {
    setOpenDialog(true)
    setTypeOperation(type)
  }

  function handlerOpenApplyDialog () {
    setOpenApplyDialog(true)
  }

  function handleClose (confirmed, type) {
    setOpenDialog(false)
    if(confirmed){
      switch(type){
        case 'save': handlerOpenApplyDialog(); break;
        case 'refresh': handleRefresh(); break;
        case 'reset': handleReset(); break;
      }
    }
  }

  function handleApplyClose (confirmed, type) {
    setOpenApplyDialog(false)
    if(confirmed){
      switch(type){
        case 'apply': handleSave('apply'); break;
        case 'save': handleSave('save'); break;
      }
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container
        direction="row"
        gap={2}
        sx={{
          justifyContent: "center",
          alignItems: "stretch",
        }}>

        <Grid item
          container
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "stretch",
            height: "stretch"
          }}
        >
          {rows.length > 0 ? (
            <Box sx={{ minHeight: '1400px', maxHeight: '2000px', width: '100%' }} >
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                processRowUpdate={processRowUpdate} // Correctly handle row updates
                onProcessRowUpdateError={(e) => console.log(e)} // Handle any processing errors
              />
            </Box>
          ) : (
            <SkeletonComponent height='1400px' />
          )}
          {selectedRows && selectedRows.length > 0 && <span style={{color:'white', width: '100%', textAlign:'left'}}>*Le azioni saranno applicate solo sulle righe selezionate</span>}
        </Grid>

        <Grid item container direction="row" sx={{justifyContent: "center", alignItems: "stretch", height: "stretch"}} gap={2} >
          <button className={"button-query"} onClick={ (e) => handlerOpenDialog('save') }>Salva le correzioni</button>
          <button className={"button-query"} onClick={ (e) => handlerOpenDialog('refresh') }>Ricarica i dati</button>
          <button className={"button-query"} onClick={ (e) => handlerOpenDialog('reset') }>Ripristina come da origine</button>
        </Grid>

        <FullscreenSpinner isLoading={isLoading} />
        <CustomSnackbar open={open} setOpen={setOpen} message={isError.message} correct={isError.correct} />
        <DialogComponent open={openDialog} handleClose={handleClose} typeOperation={typeOperation}/>
        <ApplyDialogComponent open={openApplyDialog} handleClose={handleApplyClose}/>
      </Grid>
    </ThemeProvider>
  );
}
