import axios from 'axios';

const getEntitiesData = async (getToken, idDashboard, indexDashboard, requestBody: any) => {
    try {
        requestBody.token = getToken();        
        let url = process.env.REACT_APP_BACKEND_URL+idDashboard.toLowerCase()+'/'+((idDashboard.toLowerCase() === 'overview')?requestBody.chart_type.toLowerCase():'')

        const response = axios.post(url, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Access-Control-Allow-Origin':"*"
                }
            });
        return response;  // Adjusted to return the correct part of the response
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fecthDataGraph = (currentDashboardGraph, getToken, idDashboard, indexDashboard, listGraph, setDataGraph) => {
        if(currentDashboardGraph && currentDashboardGraph.length > 0) {
            listGraph.forEach((el) => {
                getEntitiesData(getToken, idDashboard, indexDashboard, currentDashboardGraph[el].payload)
                .then((response) => {
                    if(response.data.data.length > 0 || Object.keys(response.data.data).length > 0) setDataGraph(el, response.data)
                })
                .catch((error) => {
                    // console.log(error);
                })
            });
        }
    }