import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { formatLegend, formatNumber, generateColorPalettePie } from "../../utils/graphUtils.tsx";
import { useMediaQuery, useTheme } from "@mui/material";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

type EChartsOption = echarts.ComposeOption<
  echarts.EChartsCoreOption & PieSeriesOption
>;

const PieChartComponent = ({ data, color = 'red', sizeGraph = 'large', isLargeScreen }) => {
  // Sort data descending
  const sortedData = [...data].sort((a, b) => a.value - b.value);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Usa il breakpoint per determinare la larghezza dello schermo

  const option: EChartsOption = {
    color: generateColorPalettePie(sortedData.map(el => el.value), color).reverse(),
    tooltip: {
      valueFormatter: formatNumber,
      trigger: "item",
      textStyle: {
          fontFamily: 'Custom_font',
          align: 'left' // Allinea il testo del tooltip a sinistra
      },
    },
    grid: {
            top: '20%',
            bottom: '5%',
            left: '20%',
            right: '0%',
            containLabel: true
    },
    legend: {
      show: !isMediumScreen,      
      formatter: formatLegend,
      type: 'scroll',
      orient: "vertical",
      top: "center",
      left: "left",
      textStyle: {        
        fontFamily: 'Custom_font',
        color: "#fff", // White text for legend
      },      
      pageIconColor: '#fff', // Colore delle frecce di scorrimento
      pageTextStyle: {
          fontFamily: 'Custom_font',
          color: '#fff' // Colore del numero di pagina
      },
      data: (sortedData || []).slice().reverse() // Inverti l'ordine dei dati della legenda
    },
    series: [
      {
        type: "pie",
        radius: (sizeGraph==='large' || !isMediumScreen)?["0%", "80%"]:["0%", "50%"], // Adjusted inner and outer radius to make the pie chart smaller
        center: (isMediumScreen)?["50%", "50%"]:["60%", "50%"], // Optional: keep the pie chart centered
        data: sortedData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          fontFamily: 'Custom_font',
          show: true,
          formatter: "{b}", // Display the label and percentage
          color: "#fff", // White text for pie chart labels
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: (sizeGraph !== 'adapt')?"400px":'400px', width: "100%" }} // Container size remains the same
      notMerge={true}
      lazyUpdate={true}
    />
  );
};

export default PieChartComponent;
