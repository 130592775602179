import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph } from "../utils/promiseUtils.tsx";
import ScoreCardComponent from "../elements/ScoreCardComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import PieChartComponent from "../graphs/PieChart/PieChartComponent.tsx";
import MixedChartComponent from "../graphs/MixedChart/MixedChartComponent.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";

export const Social = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const [dataGraph6, setDataGraph6] = React.useState<any>(null)
    const [dataGraph7, setDataGraph7] = React.useState<any>(null)
    const [dataGraph8, setDataGraph8] = React.useState<any>(null)
    const [dataGraph9, setDataGraph9] = React.useState<any>(null)
    const [dataGraph10, setDataGraph10] = React.useState<any>(null)
    const [dataGraph11, setDataGraph11] = React.useState<any>(null)
    const [dataGraph12, setDataGraph12] = React.useState<any>(null)
    const [dataGraph13, setDataGraph13] = React.useState<any>(null)
    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 1: setDataGraph2({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 2: setDataGraph3({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 3: setDataGraph4({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 4: setDataGraph5({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 5: setDataGraph6({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 6: setDataGraph7({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 7: setDataGraph8({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 8: setDataGraph9({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 9: setDataGraph10({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 10: setDataGraph11({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 11: setDataGraph12({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 12: setDataGraph13({ title: currentDashboardGraph[index].title, data: responseData.data });break;
        }
    }

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, indexDashboard, [0,1,2,3,4,5,6,7,8,9,10,11,12], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} >{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<ScoreCardComponent data={dataGraph1.data} direction="horizontal"/>} title={''} />
                    :<SkeletonComponent height='132px'/>}
                </Grid>
                
            </Grid>
        
            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph2 && dataGraph2.data)?<MainCard element={<PieChartComponent data={dataGraph2.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph2.title} /> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph3 && dataGraph3.data)?<MainCard element={<PieChartComponent data={dataGraph3.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph3.title} /> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph4 && dataGraph4.data)?<MainCard element={<PieChartComponent data={dataGraph4.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph4.title} /> :<SkeletonComponent/>} </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph5 && dataGraph5.data)?<MainCard element={<PieChartComponent data={dataGraph5.data} sizeGraph={'small'} color={'blue'} isLargeScreen={isLargeScreen}/>} title={dataGraph5.title} /> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph6 && dataGraph6.data)?<MainCard element={<PieChartComponent data={dataGraph6.data} sizeGraph={'small'} color={'blue'} isLargeScreen={isLargeScreen}/>} title={dataGraph6.title} /> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph7 && dataGraph7.data)?<MainCard element={<PieChartComponent data={dataGraph7.data} sizeGraph={'small'} color={'blue'} isLargeScreen={isLargeScreen}/>} title={dataGraph7.title} /> :<SkeletonComponent/>} </Grid>
            </Grid>
            
            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph8 && dataGraph8.data)?<MainCard element={<MixedChartComponent data={dataGraph8.data} type={'line'}/>} title={dataGraph8.title} /> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph9 && dataGraph9.data)?<MainCard element={<MixedChartComponent data={dataGraph9.data} type={'line'}/>} title={dataGraph9.title} /> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph10 && dataGraph10.data)?<MainCard element={<MixedChartComponent data={dataGraph10.data} type={'line'}/>} title={dataGraph10.title} /> :<SkeletonComponent/>} </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph11 && dataGraph11.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph11.data}/>} title={dataGraph11.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph12 && dataGraph12.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph12.data}/>} title={dataGraph12.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph13 && dataGraph13.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph13.data}/>} title={dataGraph13.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

        </Grid>
    )
}