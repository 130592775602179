import React from 'react';
import EChartsReact from "echarts-for-react";
import { formatGrouppedLegend, formatNumber, formatNumLabel, generateColorPalette } from '../../utils/graphUtils.tsx';
import '../../../style/graph.css'; // Importa il file CSS

const BarChartWithLabelsComponent = ({ data, color = 'red', sizeGraph='large', sort = true, showLabel= false, perc = false}) => {
    // Ordina i valori in ordine crescente e mantieni l'associazione con le categorie
    let sortedData = data['yAxis'].map((value, index) => ({
        value,
        name: data['xAxis'][index]
    }))

    sortedData = (sort)? sortedData.sort((a, b) => a.value - b.value):sortedData; // Ordinamento decrescente

    const sortedValues = sortedData.map(item => item.value);
    const sortedSubcategories = sortedData.map(item => item.name);

    // Genera una scala di colori rossi
    const colors = generateColorPalette(sortedValues, color);

    // Determina l'altezza dinamica del grafico in base al numero di categorie
    const dynamicHeight = Math.max(sortedSubcategories.length * 30, 400);  

    const option = {
        tooltip: {
            textStyle: {                
                fontFamily: 'Custom_font',                
                align: 'left' // Allinea il testo del tooltip a sinistra
            },
            formatter: (params) => {
                if (!perc) return `${params[0].name}: ${formatNumber(params[0].value)}`;
                else  return `${params[0].name}: ${formatNumber(params[0].value)}%`;
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    color: '#ffffff' // Colore del testo del tooltip
                }
            }
        },
        grid: {
            top: '0%',
            bottom: '0%',
            left: '0%',
            right: '1%',
            containLabel: true
        },
        xAxis: [
            {  
                type: 'value',
                axisLabel: {
                    formatter: (value) => (perc)?`${formatNumLabel(value)}%`:`${formatNumLabel(value)}`, // Aggiungi '%' al valore dell'asse x
                    fontFamily: 'Custom_font',
                    alignWithLabel: true,
                    color: '#fff' // Colore delle etichette dell'asse X
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse x
                }
            }
        ],
        yAxis: [
            {  
                type: 'category',
                data: sortedSubcategories,
                axisTick: {                    
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter: formatGrouppedLegend,
                    fontFamily: 'Custom_font',
                    color: '#fff', // Colore delle etichette dell'asse Y
                    interval: 0, // Mostra tutte le etichette
                },
                //name: data['label'].padEnd(data['label'].length+(data['label'].length/2),' '),
                nameLocation: 'end', // Posizione del nome dell'asse Y
                nameTextStyle: { 
                    fontSize: 14, // Dimensione del testo del nome dell'asse Y
                    color: '#fff' // Colore del nome dell'asse Y
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse y
                }
            }
        ],
        series: [
            {
                name: 'Value',
                type: 'bar',
                barWidth: '60%',
                data: sortedValues,
                label: {
                    show: showLabel, // Mostra le etichette se showLabel è true
                    fontFamily: 'Custom_font',
                    fontSize: 17, // Dimensione del testo del nome dell'asse Y
                    formatter: (value) => (perc)?`${value.value}%`: `${value.value}`,
                    color: '#fff',
                    textBorderColor: '#000', // Colore del bordo del testo (bianco)
                    textBorderWidth: 2, // Larghezza del bordo
                },
                itemStyle: {
                    fontFamily: 'Custom_font',
                    color: ((color === 'neutral')?'red':(params) => colors[params.dataIndex])
                }
            }
        ]
    };

    return (
        <div className="chart-container">
            <EChartsReact option={option} className={'chart-wrapper'} style={{ height: `${dynamicHeight}px`, width: '95%' }} />
        </div>
    );
}

export default BarChartWithLabelsComponent;
