import React from "react";
import { Card, CardContent } from "@mui/material";
import '../../style/graph.css';

export default function MainCard ({element, title}) {
    return (
        <Card style={{borderRadius:'15px'}}>
            <CardContent>
                {title !== '' &&
                    <div className="graph-title" >
                        <span>
                            {title}
                        </span>
                    </div>
                }
                {element}
            </CardContent>
        </Card>
    )
}   