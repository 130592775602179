import React from "react";
import axios from 'axios';
import { Box, Grid } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import SkeletonComponent from "../utils/Skeleton.tsx";
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Checkbox, createTheme, ThemeProvider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddUserDialog from "./AddUserDialog.tsx";
import CustomSnackbar from '../utils/SnackBarCustomComponent.tsx';
import FullscreenSpinner from "./FullscreenSpinner.tsx";

interface TableRow {
  id: number;
  email: string;
  nome_cognome: string;
  overview: boolean;
  audience: boolean;
  live: boolean;
  on_demand: boolean;
  musica: boolean;
  advertising: boolean;
  piattaforme: boolean;
  social: boolean;
  impostazioni: boolean;
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',  // Colore per i pulsanti primari
    },
    secondary: {
      main: '#f48fb1',  // Colore per i pulsanti secondari
    },
  },
});

export const SettingsUser = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState<TableRow[]>([]);
  const columnsCheckName = ['Overview', 'Audience', 'Live', 'On Demand', 'Musica', 'Advertising', 'Piattaforme', 'Social', 'Impostazioni'];
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [isError, setIsError] = React.useState({label: '', message: '', correct: false});
  const [open, setOpen] = React.useState(false);

  const getUserData = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'users', {} )
        .then((response) => {
          resolve({ data: response.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteUserData = (email) => {
    return new Promise((resolve, reject) => {
      axios.delete(process.env.REACT_APP_BACKEND_URL + 'users', { data: { email: email}} )
        .then((response) => { })
        .catch((error) => { });
    });
  };

  const updateUserData = (updatedUserData) => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'update_users', updatedUserData)
        .then((response) => { })
        .catch((error) => { });
    })
  };

  const addUserData = (userData) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      axios.post(process.env.REACT_APP_BACKEND_URL + 'add_users', [userData])
        .then((response) => {
          if(response.data && response.data.hasOwnProperty('message')){ setOpen(true); setIsError({label: 'correct', message: 'Utente creato con successo', correct: true})} 
          else { setOpen(true); setIsError({label: 'error', message: 'Utente non creato', correct: false})}
        })
        .catch((error) => {setOpen(true); setIsError({label: 'error', message: 'Errore durante la creazione dell\'utente', correct: false}) });
        setIsLoading(false);
    })
  };

  function callMutationUserData() {
    getUserData()
    .then((response: any) => {
      let userData: TableRow[] = response.data;
      userData = userData.map((el, index) => ({ ...el, id: index }));
      setRows(userData);
    })
    .catch((error) => { console.log(error) });
  }

  React.useEffect(() => {
    callMutationUserData()
  }, [])

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleCheckboxChange = (id: number, e, field: string) => {
    let flag = true
    if(field === 'impostazioni' && !e.target.checked){
      flag = rows.filter((el) => el.impostazioni).length >= 2;
    }
    if(flag) {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, [field]: e.target.checked, editedUser: true } : row
      );
      setRows(updatedRows);
    }
  };

  const handleRefresh = () => {
    callMutationUserData()
  };

  const handleSave = () => {
    const updateRows = rows.filter(el => el.hasOwnProperty('editedUser'))
    updateUserData(updateRows)
  };

  const handleAddUser = (data) => {
    const { nome_cognome, email, ...checkboxes } = data;
    const userData = { nome_cognome: nome_cognome, email:email };

    columnsCheckName.forEach((label, index) => {
      userData[label.replace(' ','_').toLowerCase()] = checkboxes.checkboxes[index];
    });
    addUserData(userData)
  }

  const handleDelete = (email: string) => {
    deleteUserData(email);
    const updatedRows = rows.filter((row) => row.email !== email);
    setRows(updatedRows);
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: 'email', headerName: 'Email', width: 180, editable: false},
    { field: 'nome_cognome', headerName: 'Nome Cognome', width: 180, editable: false},
    ...columnsCheckName.map((el, index) => {
      return {
        field: el.toLowerCase().replace(' ', ''),
        key: 'check_' + index,
        headerName: el,
        minWidth: 150,
        headerAlign: "center",
        flex: 1,
        resizable: false,
        renderCell: (params) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox key={'check_' + index}
              disabled={(el.replace(' ', '_').toLowerCase())==='social'}
              checked={params.row[el.replace(' ', '_').toLowerCase()]}
              onChange={(e) => handleCheckboxChange(params.row.id, e, el.replace(' ', '_').toLowerCase())}
              color="primary"
            />
          </div>
        )
      } as GridColDef
    }),
    {
      field: 'delete',
      headerName: 'Elimina',
      headerAlign: 'center',
      width: 80,
      sortable:false,
      filterable:false,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Elimina"
            onClick={() => handleDelete(params.row.email)} // Gestore per l'eliminazione
          />
        </div>
      ),
    }
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container
        direction="row"
        rowGap={2}
        sx={{
          justifyContent: "center",
          alignItems: "stretch"
        }}>

        <Grid item
          container
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "stretch",
            height: "stretch"
          }}
        >
          {(rows && rows.length > 0)
            ? <Box sx={{ minHeight: '1400px', maxHeight: '2000px', width: '100%' }} >
                <DataGrid
                  rows={rows}
                  columns={columns}                      
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  
                  disableAutosize
                  pageSizeOptions={[25, 50, 100]}
                  disableRowSelectionOnClick
                />
              </Box>
            : <SkeletonComponent height='1400px' />
          }
        </Grid>

        <Grid item container direction="row" sx={{justifyContent: "center", alignItems: "stretch", height: "stretch"}} gap={2} >
          <button className={"button-query"} onClick={handleRefresh}>Aggiorna</button>
          <button className={"button-query"} onClick={handleSave}>Salva</button>
          <button className={"button-query"} onClick={handleOpenPopup}>Aggiungi Utente</button>
        </Grid>

        <FullscreenSpinner isLoading={isLoading} />
        <CustomSnackbar open={open} setOpen={setOpen} message={isError.message} correct={isError.correct}/>
        <AddUserDialog open={isPopupOpen} onClose={handleClosePopup} onSubmit={handleAddUser} dashboard={columnsCheckName} settingList={rows.map((el) => el.impostazioni)} />
      </Grid>
    </ThemeProvider>
  )
}