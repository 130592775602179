import React from 'react';
import { Grid } from "@mui/material";
import { formatNumber } from '../utils/graphUtils.tsx';
import '../../style/graph.css';

// Define a TypeScript interface for the data
interface DataItem {
  'Web Impressions': number;
  '% Utenti Loggati': number;
  '#Users': number;
  '#Touchpoints': number;
}

// Define the type for props
interface ScoreCardComponentProps {
  data: DataItem[];
  direction?: string;
}

// DataDisplay Component to render a list of data
const ScoreCardComponent: React.FC<ScoreCardComponentProps> = ({ data, direction='vertical' }) => {
  return (
    <Grid 
      container
      direction={(direction === 'vertical')?"column":"row"}
      sx={{
        justifyContent: (direction === 'vertical')?"space-evenly":"space-between",
        alignItems: (direction === 'vertical')?"flex-start":"center",
      }}
      style={{ borderRadius: '15px', backgroundColor: 'var(--background-score-card)', height: (direction === 'vertical')?"400px":"100px", width: '100%' }}
    >
      {data && data.map((item, index) => (   
        <React.Fragment key={"fragment_"+index}>   
          {Object.entries(item).map(([key, value], idx) => (
            <Grid item key={'score-card_'+ idx} className='score-card-item'>
              <div key={'title'+idx}>
                <h3 className={'title-card'}>{key}</h3>
              </div>
              <div key={'text'+idx}>
                <p className={'text-card'}>{(key.includes('%'))?(formatNumber(value*100)+'').substring(0, 6)+'%':formatNumber(value)}</p>
              </div>
            </Grid>
          ))}
        </React.Fragment>  
      ))}
    </Grid>
  );
};

export default ScoreCardComponent;
