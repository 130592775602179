export const generateColorPalette = (data, color) => {
  const startColor = (color === 'red') ? [255, 230, 230] : [204, 229, 255];  // Colore iniziale senza tonalità violacee
  const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255]; // Mantieni colori puri di rosso o blu
  const maxValue = Math.max(...data); // Trova il valore massimo in 'data'
  const exponent = 1.5; // Aggiungi un esponente per enfatizzare il gradiente

  if (data.length > 1) {
    const colorPalette = data.map((item) => {
      const ratio = Math.pow(item / maxValue, exponent); // Applica l'esponente per enfatizzare il gradiente
      const color = startColor.map((start, i) => {
        return Math.round(start + ratio * (endColor[i] - start)); // Calcola il colore in base al rapporto amplificato
      });
      return rgbToHex(color); // Converte l'RGB in esadecimale
    });
    return colorPalette;
  } 
  return [rgbToHex(endColor)]; // Ritorna il colore finale come palette
};

export const generateColorPalettePie = (data, color) => {
  const startColor = (color === 'red') ? [255, 230, 230] : [204, 229, 255];  // Colore iniziale senza tonalità violacee
  const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255]; // Mantieni colori puri di rosso o blu
  const maxSteps = Math.min(data.length, 10);

  if (data.length > 1) {
    const palette:any = [];

    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        palette.push(rgbToHex(endColor)); 
      } else if (i < maxSteps) {
        const index = maxSteps - 1 - Math.min(i + 1, maxSteps - 1); 
        const factor = index / maxSteps;

        // Mantieni solo il componente rosso o blu, evitando il blu se il colore è rosso e viceversa
        const r = (color !== 'red') ? Math.round(startColor[0] + factor * (endColor[0] - startColor[0])) : startColor[0]; // Rosso
        const g = Math.round(startColor[1] + factor * (endColor[1] - startColor[1])); // Blu
        const b = Math.round(startColor[2] + factor * (endColor[2] - startColor[2])); // Blu

        palette.push(rgbToHex([r, g, b]));
      } else {
        palette.push(rgbToHex(startColor));        
      }
    }
    return palette;
  } 
  return [rgbToHex(endColor)]; // Ritorna il colore finale come palette
};

// Funzione per calcolare la media dei dati
export const calculateAverage = (data) => {
    const total = data.reduce((acc, value) => acc + value, 0);
    return total / data.length;
};

// Funzione per calcolare il colore in base all'indice di media con enfatizzazione del gradiente e senza tonalità violacee
export const getColorBasedOnIndex = (index, totalIndices, color) => {
    // Aggiorna i colori per evitare tonalità violacee
    const startColor = (color === 'red') ? [255, 230, 230] : [204, 229, 255]; // Blu chiaro senza toni violacei
    const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255]; // Rosso o Blu

    const exponent = 1.5; // Esponente per enfatizzare il gradiente
    const scale = Math.pow(index / totalIndices, exponent); // Applica l'esponente per amplificare il gradiente

    const newColor = startColor.map((c, i) => Math.round(c + scale * (endColor[i] - c)));

    return `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
};


// Funzione di supporto per convertire RGB in esadecimale
const rgbToHex = (rgb) => {
  return `#${rgb.map(c => c.toString(16).padStart(2, '0')).join('')}`;
};

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat('it-IT').format(num);
};

// Funzione per formattare i label dell'asse
export const formatDataLabel = (value) => {
    if (typeof value === 'string') {
        return value.replace("T00:00:00", '');
    }
    return value;
};

export const formatNumLabel = (value) => {
    if (value >= 1000) {
        return `${(value / 1000).toFixed(0)} K`; // Mostra "x K" per valori >= 1000
    }
    return value; // Mostra il valore normale per valori < 1000
};

export const formatLegend = (value) => {
    if(value.length > 20) return value.substring(0, 17) + '...'
    return value
}

export const formatGrouppedLegend = (value) => {
    if(value.length > 40) return value.substring(0, 27) + '...'
    return value
}