export const GraphData = {
    'overview': [
        {title:'SCORECARD AUDIENCE', payload:{start_date: '', end_date: '', chart_type: 'SCORECARDS'}},
        {title:'AUDIENCE', payload:{start_date: '', end_date: '', chart_type: 'AUDIENCE'}},
        {title:'CANZONI', payload:{start_date: '', end_date: '', chart_type: 'MUSIC'}},
        {title:'CANALI E PROGRAMMI', payload:{start_date: '', end_date: '', chart_type: 'CONTENUTI_LIVE'}},
        {title:'ADVERTISING', payload:{start_date: '', end_date: '', chart_type: 'ADVERTISING'}},
        {title:'UTENTI UNICI', payload:{start_date: '', end_date: '', chart_type: 'TER1_1'}},
        {title:'FREQUENZA', payload:{start_date: '', end_date: '', chart_type: 'TER1_2'}}, 
        {title:'CONTATTI EQUIVALENTI', payload:{start_date: '', end_date: '', chart_type: 'TER1_3'}},
        {title:'DETTAGLIO', payload:{chart_type: 'TER2'}},
    ], 
    'audience': [
        {title:'CANALI', payload:{start_date: '', end_date: '', group_by: ["name"], name:[], macro_category:[], type: "LIVE", chart_type: "PIE_CHART"}},
        {title:'TOUCHPOINTS VS. UTENTI', payload:{start_date: '', end_date: '', group_by: ["day_param"],  name:[], macro_category:[], chart_type: "MIXER_BAR_LINE_CHART"}},
        {title:'DISTRIBUZIONE TOUCHPOINT SUL GIORNO', payload:{start_date: '', end_date: '', group_by: ["hour_trunc_param"],  name:[], macro_category:[], chart_type: "MIXER_BAR_LINE_CHART"}},
        {title:'CATEGORIA', payload:{start_date: '', end_date: '', group_by: ["macro_category"],  name:[], macro_category:[], chart_type: "PIE_CHART"}}
    ], 
    'contenuti_live': [
        {title:'AUDIENCE CANALI LIVE', payload:{start_date:"", end_date:"",group_by:["name"], name:[], program_title:[], chart_type:"PIE_CHART"}},
        {title:'PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["program_title"], name:[], program_title:[], chart_type:"SINGLE_BAR_CHART"}},
        {title:'CANALI E PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["name","program_title"], name:[], program_title:[], chart_type:"CONCAT_BAR_CHART"}},
        {title:'CONTENUTI PLAY', payload: {start_date:"", end_date:"",group_by:["day_param","name"], name:[], program_title:[], chart_type:"STACKED_BAR_CHART"}},
        {title:'SPEAKERS', payload: {start_date:"", end_date:"", group_by:["speaker_name"], name:[], program_title:[], chart_type:"SINGLE_BAR_CHART"}}
    ], 
    'contenuti_on_demand': [
        {title:'CANALE', payload: {start_date:"",end_date:"", group_by:["channel"], channel:[], series:[], program: [], chart_type:"PIE_CHART"}},
        {title:'SERIE', payload: {start_date:"",end_date:"", group_by:["series"], channel:[], series:[], program: [], chart_type:"PIE_CHART"}},
        {title:'CANALE, SERIE E PROGRAMMA', payload: {start_date:"", end_date:"", group_by:["channel","series","program"], channel:[], series:[], program: [], chart_type:"CONCAT_BAR_CHART"}},
        {title:'PROGRAMMA TITOLO', payload: {start_date:"", end_date:"", group_by:["program","name"], channel:[], series:[], program: [],  chart_type:"CONCAT_BAR_CHART"}},
        {title:'CONTENUTO', payload: {start_date:"",end_date:"",group_by:["content"], channel:[], series:[], program: [], chart_type:"SINGLE_BAR_CHART"}},
        {title:'PROGRAMMA', payload: {start_date:"",end_date:"",group_by:["program"], channel:[], series:[], program: [], chart_type:"SINGLE_BAR_CHART"}}
    ],
    'music': [
        {title:'TOP CANZONI', payload: {start_date:"",end_date:"",group_by:["mus_sng_title"], source:[], title:[], chart_type:"SINGLE_BAR_CHART"}},
        {title:'TOP ARTISTI', payload: {start_date:"",end_date:"",group_by:["mus_art_name"], source:[], title:[],chart_type:"SINGLE_BAR_CHART"}},
        {title:'TOP ARTISTI PER PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["mus_art_name","title"], source:[], title:[], chart_type:"CONCAT_BAR_CHART"}},
        {title:'TOP CANZONI PER PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["mus_sng_title","title"], source:[], title:[], chart_type:"CONCAT_BAR_CHART"}}
    ],
    'advertising': [
        {title:'CANALI', payload: {start_date:"",end_date:"",group_by:["source"],source:[], name:[], chart_type:"PIE_CHART"}},
        {title:'PROGRAMMI', payload: {start_date:"",end_date:"",group_by:["title"],source:[], name:[],chart_type:"PIE_CHART"}},
        {title:'ADVERTISING', payload: {start_date:"",end_date:"",group_by:["name"],source:[], name:[],chart_type:"SINGLE_BAR_CHART"}},
        {title:'FRUIZIONE ADVERTISING', payload: {start_date:"",end_date:"",group_by:["day_param"], source:[], name:[],chart_type:"SINGLE_BAR_CHART"}}
    ],
    'platforms': [
        {title:'CATEGORIA', payload: {start_date:"",end_date:"",group_by:["macro_category"],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'PIATTAFORME', payload: {start_date:"",end_date:"",group_by:["platform"],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'SISTEMI OPERATIVI', payload: {start_date:"",end_date:"",group_by:["os_name"],macro_category:[],platform:[],chart_type:"SINGLE_BAR_CHART"}},
        {title:'AUDIENCE PER CATEGORIA', payload: {start_date:"",end_date:"",group_by:["day_param","macro_category"],macro_category:[],platform:[],chart_type:"STACKED_BAR_CHART"}}
    ],
    'social': [
        {title:'SCORECARD AUDIENCE', payload:{start_date: '', end_date: '', chart_type: 'SCORECARDS'}},
        {title:'MEDIA GIORNALIERA DI ACCOUNT RAGGIUNTI', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'MEDIA GIORNALIERA DI IMPRESSIONS', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'ENGAGEMENT GIORNALIERO MEDIO', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"PIE_CHART"}},
        {title:'', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"PIE_CHART"}},

        {title:'MEDIA GIORNALIERA DI ACCOUNT RAGGIUNTI', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"MIXER_BAR_LINE_CHART"}},
        {title:'MEDIA GIORNALIERA DI IMPRESSIONS', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"MIXER_BAR_LINE_CHART"}},
        {title:'ENGAGEMENT GIORNALIERO MEDIO', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"MIXER_BAR_LINE_CHART"}},

        {title:'TOP FACEBOOK POSTS PER NUMERO DI IMPRESSIONS', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"CONCAT_BAR_CHART"}},
        {title:'TOP INSTAGRAM POSTS PER NUMERO DI IMPRESSIONS', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"CONCAT_BAR_CHART"}},
        {title:'TOP CONTENUTI PIÙ PIACIUTI (LIKES O REAZIONI AL POST)', payload: {start_date:"",end_date:"",group_by:[],macro_category:[],platform:[],chart_type:"CONCAT_BAR_CHART"}},
    ]
};

export const FilterData = {
    'overview': {},
    'audience': {table_name: 'audience', columns: ['name', 'macro_category'], columns_alias:['Nome', 'Categoria'],  type: 'LIVE'},
    'contenuti_live': {table_name: 'contenuti_live', columns: ['name', 'program_title'], columns_alias: ['Nome', 'Titolo programma']},
    'contenuti_on_demand': {table_name: 'contenuti_on_demand', columns: ['channel', 'series','program'], columns_alias: ['Canale', 'Serie','Programma']},
    'music': {table_name:'music', columns: ['source','title'], columns_alias: ['Sorgente','Titolo']},
    'advertising': {table_name:'advertising', columns: ['source','name'], columns_alias: ['Sorgente','Nome']},
    'platforms': {table_name:'platforms', columns: ['macro_category','platform'], columns_alias: ['Categoria','Piattaforma']},
    'social': {table_name:'social', columns: ['social','page'], columns_alias: ['Social','Pagina']}
}