import dayjs from 'dayjs';
import React from 'react';

export interface FilterContextEl {
    name: string;
    id: string;
    filtered?: string[];
    impacted?: string[];
    optionList: {name: string, id: string}[],
    type: string;
    selectedValue?: any|undefined,
    isAllSelected?: boolean,
}

interface FilterContextType {
    addFilters: (filters: FilterContextEl[]) => FilterContextEl[];
    isAllSelected: (filterName: string) => boolean|undefined;
    getFilterValueList: (filterName: string) => any|undefined;
    setFilterValueList: (filterName: string, newFilter: any, clickedOption: any) => void;
    getFilteredOptionList: (filterElement: any, selectedOptions: any) => {name: string}[]; 
}

const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
    const [filters, setFilters] = React.useState<FilterContextEl[]>([]);

    React.useEffect(() => {
      const dateFilterList = [{ name:'startDate', id:'startDate', optionList:[], type: 'datepicker', selectedValue: dayjs().subtract(1, 'week') },
        { name:'endDate', id:'endDate', optionList:[], type: 'datepicker', selectedValue: dayjs() },
      ]
      setFilters(dateFilterList)
    },[])

    const addFilters = (filtersList: FilterContextEl[]) => {
      const selectedList: FilterContextEl[] = [...filters]
      filtersList.forEach((filter) => {
          if(filter.optionList.length > 0) filter.optionList = [{name: 'All', id: 'All'},...filter.optionList]
          const currentFilter = selectedList.find(obj => obj.id === filter.id);
          if (currentFilter) {
            currentFilter.selectedValue = getFilterValueList(filter.id); 
            filter.selectedValue = currentFilter.selectedValue;
          }
          else selectedList.push(filter)               
      })
      setFilters([...selectedList])
      return filtersList.filter((filter) => filter.type !== 'datepicker');
    };

    const getFilterValueList = (filterName: string) => {
        const currentFilter = filters.find((el) => el.id === filterName)        
        if (currentFilter) {return currentFilter.selectedValue;}
        return [];
    };

    const isAllSelected = (filterName) => {
      let currentFilter: FilterContextEl|undefined = filters.find(obj => obj.id === filterName);
      // Controlla se il filtro esiste già
      if (currentFilter)
        return currentFilter.isAllSelected
      return false
    }

    const setFilterValueList = (filterName: string, newFilter: any, clickedOption: any) => {
        setFilters((prevFilters) => {
          let currentFilter: FilterContextEl|undefined = prevFilters.find(obj => obj.id === filterName);
          // Controlla se il filtro esiste già
          if (currentFilter) {
            if(currentFilter.type === "multiselector") {
              const optionList = getFilteredOptionList(currentFilter, (currentFilter.filtered)? getFilterValueList(currentFilter.filtered[0]) || []:[])
              if(clickedOption === 'All') {
                if (!currentFilter.isAllSelected) {
                  currentFilter.isAllSelected = true;
                  currentFilter.selectedValue = optionList.filter(obj => obj.id !== 'All');
                } else {
                  currentFilter.isAllSelected = false;
                  currentFilter.selectedValue = [];
                }
              } else {
                if(clickedOption === null || clickedOption === undefined || (currentFilter.selectedValue && currentFilter.selectedValue.find(obj => obj.id === clickedOption)) ){ // click sulla 'x'
                  currentFilter.isAllSelected = false;
                }
                if (!filterName.includes('data') && clickedOption !== 'All' && currentFilter.isAllSelected) {
                  currentFilter.selectedValue = newFilter.filter((el) => el.id !== 'All');
                }
                else currentFilter.selectedValue = newFilter;
              }
              if(currentFilter.impacted) 
                currentFilter.impacted.forEach((filter) => {
                let impactedFilter = prevFilters.find(obj => obj.id === filter);
                if (impactedFilter) {
                  impactedFilter.selectedValue = []
                  impactedFilter.isAllSelected= false
                }
              })
            }
            else{
              currentFilter.selectedValue = newFilter
            }
          }
          return [...prevFilters];
        });
    };

    const getFilteredOptionList = (filterElement: any, selectedOptions: any) => {      
      if(selectedOptions.length > 0){
        selectedOptions = selectedOptions.map(el => el.id)
        return filterElement.optionList.filter(el => el.root && el.root.some(rootItem => selectedOptions.includes(rootItem)) || el.id === 'All')
      }
      return filterElement.optionList
    };
 
    return (
    <FilterContext.Provider
      value={{
        addFilters,
        isAllSelected,
        getFilterValueList,
        setFilterValueList,
        getFilteredOptionList
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = (): FilterContextType => {
  const context = React.useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within an FilterProvider');
  }
  return context;
};