import React from 'react';
import { Grid } from '@mui/material';
import SkeletonComponent from '../utils/Skeleton.tsx';
import BarChartWithLabelsComponent from '../graphs/BarChart/BarChartWithLabelsComponent.tsx';
import BarChartVerticalWithLabelsComponent from '../graphs/BarChart/BarChartVerticalWithLabelsComponent.tsx';
import axios from 'axios';

// const data = [
//     {   title: 'Percentuale di corrispondenza', 
//         data: {xAxis: [ 'Audience', 'Live', 'On Demand', 'Musica', 'Advertising', 'Overall'], yAxis: [10, 100, 90, 70, 20 ,50] }},
//     {   title: 'Numero delle corrispondenze', 
//         data: {xAxis: [ 'Audience', 'Live', 'On Demand', 'Musica', 'Advertising', 'Overall'], yAxis: [10, 100, 90, 70, 20 ,50] }},
// ];

const DataQuality = ( ) => {
    const [dataGraph, setDataGraph] = React.useState<any>(null);

    const getLabelData = () => {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'get_data_quality', {} )
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    function callMutationUserData() {
      getLabelData()
      .then((response: any) => {
        let userData = response.data;
        setDataGraph(userData);
      })
      .catch((error) => { console.log(error) });
    }

    React.useEffect(() => {
        callMutationUserData();
    }, []);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='40px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >

                <Grid item xs={12}>{(dataGraph && dataGraph[0].data)
                    ?<div>
                        <div className="graph-quality-title" >
                            <span>
                                {dataGraph[0].title}
                            </span>
                        </div>
                        <BarChartWithLabelsComponent data={dataGraph[0].data} showLabel={true} perc={true}/>
                    </div>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >

                <Grid item xs={12}>{(dataGraph && dataGraph[0].data)
                    ?<div>
                        <div className="graph-quality-title" >
                            <span>
                                {dataGraph[1].title}
                            </span>
                        </div>
                        <BarChartVerticalWithLabelsComponent data={dataGraph[1].data} color={'blue'} showLabel={true}/>
                    </div>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DataQuality;
